.filter-pill {
    width: 281px;
    height: 35px;
    margin-bottom: 50px;
    border-radius: 17.5px;
    border: solid 1px #fe6551;
    display: flex;
    justify-content: space-between;
    font-family: "Martel Sans", sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #fe6551;
}

.right-option {
    height: 35px;
    border-radius: 0 17.5px 17.5px 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-option:hover {
    cursor: pointer;
}

.active-option {
    color: #fff;
    background-color: #fe6551;
}

.left-option {
    height: 35px;
    border-radius: 17.5px 0 0 17.5px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-option:hover {
    cursor: pointer;
}