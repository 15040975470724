.upcoming-appointments {
    margin-bottom: 50px;
}

.appointment {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actions span {
    font-family: "Martel Sans", sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #ff5a56;
}

.actions span:hover {
    cursor: pointer;
}

.plan-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.plan {
    font-family: "MartelSans", sans-serif;
    font-weight: 600;
    color: #4c4c4c;
    line-height: 1.43;
}