.Card {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(173, 173, 173, 0.5);
  background-color: #fff;
  align-self: flex-start;
}

.cardbio-content h4 {
  color: #4c4c4c;
  font-size: 14px;
  font-weight: bold;
}

.cardbio-content p {
  color: #757575;
  font-size: 12px;
}

.name {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-bottom: 1.5rem;
;
}

h4 {
  margin-bottom: 0;
}

p {
  margin-top: 0;
}

a {
  text-decoration: none;
}

.name {
  font-family: "Cantata One", serif;
  font-weight: normal;
}

.more {
  font-family: "Martel Sans", sans-serif;
  color: #ff5a56;
  font-weight: bold;
}

.more:hover {
  cursor: pointer;
}

.cardbio-content {
  font-family: "Martel Sans", sans-serif;
}

.circle {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star {
  position: absolute;
  top: 33px;
  left: 35px;
}